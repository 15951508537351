<template>
    <div class="coupon-container">
        <div class="head-con">
            <h3>优惠券</h3>
            <div class="addcon" @click="addAddress">
                <p>领取更多优惠券</p>
                <el-icon><arrow-right /></el-icon>
            </div>
        </div>
        <div class="menu">
            <el-menu
                default-active="1"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
            >
                <el-menu-item index="1">未使用({{wsy_num}})</el-menu-item>
                <el-menu-item index="2">已使用({{ysy_num}})</el-menu-item>
                <el-menu-item index="3">已过期({{ygq_num}})</el-menu-item>
            </el-menu>
        </div>

        <div class="coupon-content" v-if="list.lenght>0">
            1
        </div>
        <div class="coupon-content" v-else>
            <img src="@/assets/image/no_coupon.png" />
            <p>目前没有未使用的优惠券！</p>
            <el-button type="danger" >去领券中心</el-button>
        </div>
        
    </div>
</template>
<script>
import {ArrowRight } from '@element-plus/icons'
import {ref,onMounted,reactive, toRefs} from 'vue'
import {getData} from '@/utils/req'
export default {
    components:{
        ArrowRight
    },
    setup(){
        let pc_user =ref('')
        let currentPage = ref(0)
        let state = reactive({
            wsy_num:0,
            ygq_num:0,
            ysy_num:0,
            list:[]
        })
        onMounted(()=>{
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            axios()
        })
        const axios =()=>{
            const data ={
                module: 'app_pc',
                action: 'coupon',
                m: 'mycoupon',
                access_id:pc_user.value.access_id,
                type:currentPage.value
            }
            getData(data).then(res=>{
                if(res.code ===200){
                    state.wsy_num = res.data.wsy_num
                    state.ygq_num = res.data.ygq_num
                    state.ysy_num = res.data.ysy_num
                    state.list = res.data.list
                }
            })
        }
        const handleSelect = (e) =>{
            console.log(e);
        }
        return{
            ...toRefs(state),
            handleSelect
        }
    }
}
</script>
<style lang="scss" scoped>
    .coupon-container{
        width: 100%;
        height: 100%;
        background: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        .head-con{
            width: 100%;
            height: 56px;
            border-bottom: 1px solid #E6E6E6;
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3{
                color: #020202;
                font-size: 16px;
                font-weight: bold;
                padding-left: 16px;
                line-height: 56px;
            }
            .addcon{
                display: flex;
                align-items: center;
                padding-right: 15px;
                p{
                    color: #D4282D;
                    font-size: 14px;
                    line-height: 14px;
                    cursor: pointer;
                    padding-right: 5px;
                }
            }
        }
        .menu{
            margin-left: 10px;
        }
        .coupon-content{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            p{
                font-size: 16px;
                line-height: 16px;
                color: #888888;
                margin-top: 20px;
            }
            .el-button{
                border-radius: 0;
                width: 112px;
                height: 40px;
                font-size: 16px;
                text-align: center;
                margin-top: 30px;
            }
        }
    }

    ::v-deep .el-menu--horizontal{
        border-bottom: 0px;
    }
</style>